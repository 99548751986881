import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "table" ]

    connect(){
        $('#customers_index').DataTable( {
            order: [[1,'asc'],[0,'asc']]
        })
    }
}
