import React from 'react';
import styled from 'styled-components';
import RepairOrder from "./repair_order";
import { Droppable} from "react-beautiful-dnd";

const Container = styled.div`
  margin: 2px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 220px;
  
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;
const Title = styled.h6`
  padding: 4px;
  min-height: 45px;
`;
const RepairOrderList = styled.div`
  padding: 2px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')};  
  flex-grow: 1;
  min-height: 100px;
`;

export default class BoardStatusType extends React.Component {
    render() {
        return (
            <Container>
                <Title>{this.props.board_status_type.repair_order_status_type.name} ({this.props.repair_orders.length})</Title>
                <Droppable droppableId={this.props.board_status_type.id}>
                    {(provided, snapshot) => (
                        <RepairOrderList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {this.props.repair_orders.map((repair_order, index) => <RepairOrder key={repair_order.id} repair_order={repair_order} index={index}/>)}
                            {provided.placeholder}
                        </RepairOrderList>
                    )}
                </Droppable>
            </Container>
        );
    }
}
