// Selectize for new customer on repair order
document.addEventListener("turbolinks:load", () => {
    var selectizeCallback = null;
    var xhr;
    var select_customer, $select_customer;
    var select_vehicle, $select_vehicle;
    var addCustomerButtonClicked = false;
    var addCustomerVehicleButtonClicked = false;
    var $select_bill_to_party;
    var $select_rapair_status_type;
    var $nested_fields_container;

    //$('.selectize').selectize();
    $("[data-behavior='selectize']").selectize();
     //console.log($("*[data-action='click->nested-form#add_association']"));
    /*$("*[data-action='click->nested-form#add_association']").on('click', function(){

         $nested_fields_container = $(this).parent().prev('.nested-fields');
         init_nested_elements($nested_fields_container);
    });
    */
    /*document.addEventListener('click', function(event) {
        console.log(event.target);
        if (event.target.matches("[data-action='click->nested-form#add_association']")) {
            console.log('made it');
            $nested_fields_container = $(event.target).parent().prev('.nested-fields');
            console.log($(event.target).parent().prev('.nested-fields'))
            init_nested_elements($nested_fields_container);
        }
    }, false);


    init_nested_elements = function($container){
      console.log($container.find('.selectize')[0])

      var select_fields = $container.find('.selectize');
        select_fields.selectize();
    };*/

    $select_rapair_status_type = $('#repair_order_repair_order_status_type_id').selectize();

    $select_bill_to_party = $("#repair_order_bill_to_party_gid").selectize({
        valueField: 'item_gid',
        labelField: 'name',
        searchField: ['itemGroup', 'name'],
        maxItems: 1,
        placeholder: 'Select payer',
        optgroups: [{groupName: 'Non-insurer payer'}, {groupName: 'Insurer'}],
        optgroupField: 'itemGroup',
        optgroupLabelField: 'groupName',
        optgroupValueField: 'groupName'
        /*,
        render: {
            item: function(item, escape) {
                return '<div><span class="first_name">' + escape(item.first_name) + '</span> <span class="last_name">' + escape(item.last_name) + '</span></div>';
            },
            option: function(item, escape) {
                var phone = item.phone ? item.phone : null;
                var email = item.email ? item.email : null;
                return '<div class="container">' +
                    '<dl>' +
                    '<dt>' + escape(item.first_name) + ' ' + escape(item.last_name) + '</dt>' +
                    (phone || email ? '<dd>' : '') +
                    (phone ? '<span class="phone">' + item.phone + '</span> ' : '') +
                    (email ? '<span class="email">' + item.email + '</span>' : '') +
                    (phone || email ? '</dd>' : '') +
                    '</dl>' +
                    '</div>';
            }
        }*/
    });

    $("#new_customer_modal").on("hide.bs.modal", function(e) {
        if (selectizeCallback != null) {
            selectizeCallback();
            selectizeCallback = null;
        }

        $("#new_customer").trigger("reset");
        $("#new_customer").clear_form_errors();
        $("#new_customer").clear_form_fields();
    });

    $("#new_customer").on("submit", function(e) {
        e.preventDefault();
        $.ajax({
            method: "POST",
            url: $(this).attr("action"),
            data: $(this).serialize(),
            dataType: "json",
            success: function(response) {
                if (addCustomerButtonClicked) {
                    select_customer.setValue(null, true);
                    select_customer.clearOptions();
                    select_customer.load(function(callback) {
                        xhr && xhr.abort();
                        xhr = $.ajax({
                            url: '/customers.json',
                            success: function(results) {
                                callback(results);
                            },
                            error: function() {
                                callback();
                            }
                        })
                    });
                    xhr.done(function(data) {
                        select_customer.setValue(response.id, false);
                    });
                    $("#new_customer_modal").modal('toggle');
                } else {
                    selectizeCallback({value: response.id, text: response.first_name + " " + response.last_name});
                    selectizeCallback = null;

                    $("#new_customer_modal").modal('toggle');
                }
            },
            error: function(response) {
                //console.log(response);
                $("#new_customer").render_form_errors('customer', response.responseJSON);
                //$("#new_customer").trigger("reset");
                $('#new_customer_submit_button').removeAttr("disabled");
            }
        });
    });
    // Add listener if user clicks button to launch add customer modal
    if (document.getElementById("add_customer_button")) {
        document.getElementById("add_customer_button").addEventListener("click", function(){
            //var selectizeCallback = null;
            addCustomerButtonClicked = true;
        });
    };
    // Add listener if user clicks button to launch add customer vehicle modal
    if (document.getElementById("add_customer_vehicle_button")) {
        document.getElementById("add_customer_vehicle_button").addEventListener("click", function(){
            //var selectizeCallback = null;
            addCustomerVehicleButtonClicked = true;
        });
    };
    // Disable add customer vehicle button on load
    $('#add_customer_vehicle_button').prop('disabled', true);

    $select_customer = $("#repair_order_customer_id").selectize({
        preload: true,
        valueField: 'id',
        searchField: ['last_name', 'first_name', 'phone', 'email'],
        maxItems: 1,
        placeholder: 'Select existing customer',
        render: {
            item: function(item, escape) {
                return '<div><span class="first_name">' + escape(item.first_name) + '</span> <span class="last_name">' + escape(item.last_name) + '</span></div>';
            },
            option: function(item, escape) {
                var phone = item.phone ? item.phone : null;
                var email = item.email ? item.email : null;
                return '<div class="container">' +
                    '<dl>' +
                    '<dt>' + escape(item.first_name) + ' ' + escape(item.last_name) + '</dt>' +
                    (phone || email ? '<dd>' : '') +
                    (phone ? '<span class="phone">' + item.phone + '</span> ' : '') +
                    (email ? '<span class="email">' + item.email + '</span>' : '') +
                    (phone || email ? '</dd>' : '') +
                    '</dl>' +
                    '</div>';
            }
        },
        create: function(input, callback) {
            selectizeCallback = callback;

            $("#new_customer_modal").modal();
            //$("#category_name").val(input);
        },
        onChange: function(value) {
            if (!value.length) return;
            $('#add_customer_vehicle_button').prop('disabled', false);
            $('#customer_vehicle_customer_id').val(value);
            select_vehicle.disable();
            select_vehicle.setValue(null, true);
            select_vehicle.clearOptions();
            select_vehicle.load(function(callback) {
                xhr && xhr.abort();
                xhr = $.ajax({
                    url: '/customers/' + value + '/customer_vehicles.json',
                    success: function(results) {
                        select_vehicle.enable();
                        callback(results);
                    },
                    error: function() {
                        callback();
                    }
                })
            })
        },
        load: function(query, callback) {
            //if (!query.length) return callback();
            //console.log(this)
            this.clearOptions();
            $.ajax({
                url: '/customers.json',
                type: 'GET',
                dataType: "json",
                error: function() {
                    callback();
                },
                success: function(res) {
                    callback(res);
                }
            });
        }
    });
    //console.log('selected value: ' + $select_customer)

    $("#new_customer_vehicle_modal").on("hide.bs.modal", function(e) {
        if (selectizeCallback != null) {
            selectizeCallback();
            selectizeCallback = null;
        }

        $("#new_customer_vehicle").trigger("reset");
        $("#new_customer_vehicle").clear_form_errors();
        $("#new_customer_vehicle").clear_form_fields();
    });

    $("#new_customer_vehicle").on("submit", function(e) {
        e.preventDefault();
        $.ajax({
            method: "POST",
            url: $(this).attr("action"),
            data: $(this).serialize(),
            dataType: "json",
            success: function(response) {
                if (addCustomerVehicleButtonClicked) {
                    select_vehicle.setValue(null, true);
                    select_vehicle.clearOptions();
                    select_vehicle.load(function(callback) {
                        xhr && xhr.abort();
                        xhr = $.ajax({
                            url: '/customers/' + select_customer.getValue() + '/customer_vehicles.json',
                            success: function(results) {
                                callback(results);
                            },
                            error: function() {
                                callback();
                            }
                        })
                    });
                    xhr.done(function(data) {
                        select_vehicle.setValue(response.id, true);
                    });
                    $("#new_customer_vehicle_modal").modal('toggle');
                } else {
                    selectizeCallback({value: response.id, text: response.first_name + " " + response.last_name});
                    selectizeCallback = null;
                    select_vehicle.setValue(response.id, true);
                    $("#new_customer_vehicle_modal").modal('toggle');
                }
            },
            error: function(response) {
                //console.log(response);
                $("#new_customer_vehicle").render_form_errors('customer_vehicle', response.responseJSON);
                //$("#new_customer").trigger("reset");
                $('#new_customer_vehicle_submit_button').removeAttr("disabled");
            }
        });
    });


    $select_vehicle = $("#repair_order_customer_vehicle_id").selectize({
        valueField: 'id',
        searchField: ['year', 'make', 'model', 'color'],
        render: {
            item: function(item, escape) {
                return '<div><span class="year">' + escape(item.year) + '</span> ' +
                    '<span class="make">' + escape(item.make) + '</span> ' +
                    '<span class="model">' + escape(item.model) + '</span>, ' +
                    '<span class="color">' + escape(item.color) + '</span></div>';
            },
            option: function(item, escape) {
                var label = item.year + ' ' + item.make + ' ' + item.model + ', ' + item.color;
                return '<div class="container"><span class="label">' + escape(label) + '</span></div>';
            }
        },
        create: function(input, callback) {
            selectizeCallback = callback;

            $("#new_customer_vehicle_modal").modal();
            $("#category_name").val(input);
        },


    })

    if ($select_vehicle[0]) {select_vehicle = $select_vehicle[0].selectize};
    if ($select_customer[0]) {select_customer = $select_customer[0].selectize};

    if (select_vehicle) {select_vehicle.disable()};
    // Form errors
    $.fn.render_form_errors = function(model_name, errors) {
        var form;
        form = this;
        this.clear_form_errors();
        return $.each(errors, function(field, messages) {
            var input;
            input = form.find('input, select, textarea').filter(function() {
                var name;
                name = $(this).attr('name');
                if (name) {
                    return name.match(new RegExp(model_name + '\\[' + field + '\\(?'));
                }
            });
            input.closest('.form-group').addClass('form-group-invalid');
            input.addClass('is-invalid');
            return input.parent().append('<div class="invalid-feedback">' + $.map(messages, function(m) {
                return m.charAt(0).toUpperCase() + m.slice(1);
            }).join('<br />') + '</div>');
        });
    };

    $.fn.clear_form_errors = function() {
        this.find('.form-group').removeClass('form-group-invalid');
        this.find('.form-control').removeClass('is-invalid');
        return this.find('div.invalid-feedback').remove();
    };

    $.fn.clear_form_fields = function() {
        return this.find(':input', '#new_repair_order').not(':button, :submit, :reset, :hidden').val('').removeAttr('checked').removeAttr('selected');
    };
})
