import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "table" ]

    connect(){
        $('#insurers_index').DataTable( {
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
            order: [[0,'asc']],
            iDisplayLength: -1
        })
    }
}
