import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "links", "iconTemplate", "backgroundColorTemplate" ]

    connect() {
        //console.log('connected')
        // this.wrapperClass = this.data.get("wrapperClass") || "nested-icon-fields" || "nested-background-color-fields"
        //console.log(this.wrapperClass)
    }

    add_icon_association(event) {
        event.preventDefault()

        var content = this.iconTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML('beforebegin', content)

        var addItemLink = document.getElementById('add_nested_item_link')
        addItemLink.style.display = 'none'
    }

    add_background_color_association(event) {
        event.preventDefault()

        var content = this.backgroundColorTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML('beforebegin', content)

        var addItemLink = document.getElementById('add_nested_item_link')
        addItemLink.style.display = 'none'
    }

    remove_icon_association(event) {
        event.preventDefault()
        this.wrapperClass = this.data.get("wrapperClass") || "nested-icon-fields"

        let wrapper = event.target.closest("." + this.wrapperClass)
        console.log(this.wrapperClass)
        console.log('made it here 1')

        // New records are simply removed from the page
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
            var addItemLink = document.getElementById('add_nested_item_link')
            addItemLink.style.display = 'initial'

            // Existing records are hidden and flagged for deletion
        } else {
            console.log('made it here 2')
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = 'none'
        }
    }

    remove_background_color_association(event) {
        event.preventDefault()
        this.wrapperClass = this.data.get("wrapperClass") || "nested-background-color-fields"

        let wrapper = event.target.closest("." + this.wrapperClass)
        console.log(this.wrapperClass)
        console.log('made it here 1')

        // New records are simply removed from the page
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
            var addItemLink = document.getElementById('add_nested_item_link')
            addItemLink.style.display = 'initial'

            // Existing records are hidden and flagged for deletion
        } else {
            console.log('made it here 2')
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = 'none'
        }
    }
    remove_association(event) {
        event.preventDefault()
        let wrapper = event.target.closest("." + this.wrapperClass)
        console.log(this.wrapperClass)
        console.log('made it here 1')

        // New records are simply removed from the page
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
            var addItemLink = document.getElementById('add_nested_item_link')
            addItemLink.style.display = 'initial'

            // Existing records are hidden and flagged for deletion
        } else {
            console.log('made it here 2')
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = 'none'
        }
    }
}