import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import BoardStatusType from "./board_status_type";
import BoardHeader from"./board_header";
import $ from 'jquery';
import axios from 'axios';
const csrfToken = document.querySelector('[name=csrf-token]').content
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

const Container = styled.div`
  display: flex;
`;

class ShopBoard extends React.Component {
  constructor(props) {
    //console.log('Made it to constructor');
    super(props);
    //const boardData = {
    this.state = {
      current_user_person: {},
      board: {},
      board_status_types: {},
      board_status_type_order: [],
      repair_orders: {}
    };
    //this.state = { boardData };
    //console.log(this.state);
  }

  loadRepairOrders = () => {
    let url = ''
    if (this.props.employee) {
      url = '/api/v1/repair_orders.json?board=' + this.props.board.id + '&employee=' + this.props.employee.id;
    } else {
      url = '/api/v1/repair_orders.json?board=' + this.props.board.id;
    }
    axios.get(url).then(response => this.setState(response.data))
        .catch(function (error) {
          console.log(error);
        })
  };
  componentDidMount(){
    this.loadRepairOrders();
    this.timer = setInterval(() => this.loadRepairOrders(), 15000);
  }
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip({
      placement: 'auto'
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  //state = this.props;
  onDragEnd = result => {
    const { destination, source, draggableId } = result;

    // If drop to an invalid area
    if (!destination) {
        return;
    }

    // If dropped back to the same location
    if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
    )  {
        return;
    }

    // Set new state after drop
    const board_status_types = JSON.parse(this.state.board_status_types);
    const source_board_status_type = board_status_types.find( i => i.id === source.droppableId);
    const destination_board_status_type = board_status_types.find( i => i.id === destination.droppableId);
    const board_repair_orders = JSON.parse(this.state.repair_orders);
    const droppedRepairOrder = board_repair_orders.find( i => i.id === draggableId);
    // if dropped (reordered) in the same board status
    if (source_board_status_type === destination_board_status_type) {
      const thisStatusRepairOrders = board_repair_orders.filter( i => i.repair_order_status_type_id === source_board_status_type.repair_order_status_type_id);
      const otherStatusRepairOrders = board_repair_orders.filter( i => i.repair_order_status_type_id !== source_board_status_type.repair_order_status_type_id);
      droppedRepairOrder['position'] = destination.index + 1;
      thisStatusRepairOrders.splice(source.index, 1)
      thisStatusRepairOrders.splice(destination.index, 0, droppedRepairOrder)

      //const newRepairOrders = board_repair_orders[droppedRepairOrder.id] = droppedRepairOrder;
      //console.log(JSON.parse(this.state.board_status_types));
      //console.log(newRepairOrders);

      const newState = {
        ...this.state,
        repair_orders: JSON.stringify(otherStatusRepairOrders.concat(thisStatusRepairOrders)),
      };

      /*newRepairOrders.splice(source.index, 1)
      newRepairOrders.splice(destination.index, 0, droppedRepairOrder)

      const newBoardStatusType = {
        ...source_board_status_type,
        repair_orders: newRepairOrders
      };

      const newState = {
        ...this.state,
        board_status_types: {
          ...this.state.board_status_types,
          [newBoardStatusType.id]: newBoardStatusType,
        },
      };
      */
      this.setState(newState);

      const new_repair_order_status_type_id = destination_board_status_type.repair_order_status_type_id;
      //let data = "repair_order_id=" + draggableId + "&repair_order_status_type_id=" + new_repair_order_status_type_id + "&position=" + (destination.index + 1);
      let data = {repair_order_id: draggableId, repair_order_status_type_id: new_repair_order_status_type_id, position: (destination.index + 1)};
      //console.log(data);
      // $.ajax({
      //   url: '/shop_boards',
      //   type: "PATCH",
      //   data: data
      // });
      axios.patch('/shop_boards', data);
      return;
    }

    // Moving from one status to another
    const sourceStatusRepairOrders = board_repair_orders.filter( i => i.repair_order_status_type_id === source_board_status_type.repair_order_status_type_id);
    const destinationStatusRepairOrders = board_repair_orders.filter( i => i.repair_order_status_type_id === destination_board_status_type.repair_order_status_type_id);
    const otherStatusRepairOrders = board_repair_orders.filter( i => i.repair_order_status_type_id !== source_board_status_type.repair_order_status_type_id && i.repair_order_status_type_id !== destination_board_status_type.repair_order_status_type_id);


    // update the droppedRepairOrder status for the component state
    droppedRepairOrder['repair_order_status_type_id'] = destination_board_status_type.repair_order_status_type_id;
    droppedRepairOrder['position'] = destination.index + 1;
    droppedRepairOrder['repair_order_status_type_changed_at'] = Date.now();
    // Remove repair order from source status
    sourceStatusRepairOrders.splice(source.index, 1)
    // Add repair order to destination status
    destinationStatusRepairOrders.splice(destination.index, 0, droppedRepairOrder)

    const newRepairOrders = otherStatusRepairOrders.concat(sourceStatusRepairOrders, destinationStatusRepairOrders);
    //console.log(newRepairOrders);
    const newState = {
      ...this.state,
      repair_orders: JSON.stringify(newRepairOrders),
    };

    this.setState(newState);

    const new_repair_order_status_type_id = destination_board_status_type.repair_order_status_type_id;
    //let data = "repair_order_id=" + draggableId + "&repair_order_status_type_id=" + new_repair_order_status_type_id + "&position=" + (destination.index + 1);
    let data = {repair_order_id: draggableId, repair_order_status_type_id: new_repair_order_status_type_id, position: (destination.index + 1)};
    //console.log(data);
    axios.patch('/shop_boards', data);
    // $.ajax({
    //   url: '/shop_boards',
    //   type: "PATCH",
    //   data: data
    //});
  }
  render () {
      return (

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Container className={'content-fluid'}>
            {this.state.board_status_type_order.map(statusTypeId => {
              const board_status_types = JSON.parse(this.state.board_status_types);
              const board_status_type = board_status_types.find( i => i.id === statusTypeId);
              //console.log(this.state);
              //console.log("Repair orders");
              //console.log(typeof this.state.repair_orders);
              const board_repair_orders = JSON.parse(this.state.repair_orders);
              //console.log(typeof board_repair_orders);
              // x = ( typeof x != 'undefined' && x instanceof Array ) ? x : []
              const repair_orders = board_repair_orders.filter( i => i.repair_order_status_type_id === board_status_type.repair_order_status_type_id);
              //let repair_orders = board_repair_orders.find( i => i.repair_order_status_type_id === board_status_type.repair_order_status_type_id);
              //repair_orders = ( typeof repair_orders != 'undefined' && repair_orders instanceof Array) ? repair_orders : []
              //return board_status_type.repair_order_status_type.name;
              return <BoardStatusType key={board_status_type.id} board_status_type={board_status_type} repair_orders={repair_orders} />
            })}
          </Container>
        </DragDropContext>
      )
  }
}
export default ShopBoard