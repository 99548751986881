import { Controller } from "stimulus"
//import flatpickr from "flatpickr";

export default class extends Controller {
    static targets = [ "links", "template" ]

    connect() {
        this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    }

    add_association(event) {
        event.preventDefault()

        var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML('beforebegin', content)

        var $nested_fields_container = $(this.linksTarget).prev('.nested-fields');

        this.init_nested_elements($nested_fields_container);

        flatpickr("[data-behavior='flatpickr]"), {
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d"
        }

    }

    remove_association(event) {
        event.preventDefault()

        let wrapper = event.target.closest("." + this.wrapperClass)

        // New records are simply removed from the page
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
            var addItemLink = document.getElementById('add_nested_item_link')

            // Existing records are hidden and flagged for deletion
        } else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = 'none'
        }
    }

    init_nested_elements = function($container){
      //$container.find('.selectize').selectize();
      $container.find("[data-behavior='selectize']").selectize();
    };
}