import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
//var moment = require('moment');

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 1px;
  padding: 1px;
  margin-bottom: 1px;
  color: ${props => (props.order.bill_to_party.visual_indicator_background_color != null ? props.order.bill_to_party.visual_indicator_background_color.color.text_color : 'black')};
  background-color: ${props => (props.isDragging ? 'lightgreen' : (props.order.bill_to_party.visual_indicator_background_color != null ? props.order.bill_to_party.visual_indicator_background_color.color.color : 'white'))};
`;
//(this.props.repair_order.bill_to_party.visual_background_color ? this.props.repair_order.bill_to_party.visual_background_color.color.color : 'white')
const OrderContainer = styled.div`
  border-radius: 1px;
  padding: 1px;
  margin-bottom: 1px;
`;

const CardBody = styled.div`
  
`;

const CardFooter = styled.div``;

const WarningIcon = styled.i`
  color: red;
`;

const OrderLink = styled.a`
  color: ${props => (props.order.bill_to_party.visual_indicator_background_color != null ? props.order.bill_to_party.visual_indicator_background_color.color.text_color : 'black')};
`;

const Handle = styled.div``;

const VisualIndicatorIcon = styled.i`
  color: #0000FF;
`;

const OrderTime = styled.div``;


export default class RepairOrder extends React.Component {
    waitingOnParts = () => {
        if (this.props.repair_order.waiting_on_parts) {
            return(<WarningIcon className={'fas fa-exclamation-triangle mr-1'} data-toggle={'tooltip'} title={'Waiting on parts'}/>);
        };
    };
    renderSubletRequirementIcons = () => {
        let icons = [];
        let NewIcon;
        if (this.props.repair_order.repair_order_sublet_requirements.length === 0) {
            return;
        } else {
            this.props.repair_order.repair_order_sublet_requirements.map(sublet_requirement => {
                if (sublet_requirement.sublet_type.visual_indicator_icon != null) {
                    NewIcon = styled.i`color: ${sublet_requirement.sublet_type.visual_indicator_icon.color.color};`;
                    icons.push(<NewIcon key={sublet_requirement.id} className={sublet_requirement.sublet_type.visual_indicator_icon.icon.icon + ' mr-1'} data-toggle={'tooltip'} title={'Sublet: ' + sublet_requirement.sublet_type.name} />);
                }
            });
        };
        return icons;
    };
    countUpFromTime = (countFrom, id) => {
        countFrom = new Date(countFrom).getTime();
        var now = new Date(),
            countFrom = new Date(countFrom),
            timeDifference = (now - countFrom);

        var secondsInADay = 60 * 60 * 1000 * 24,
            secondsInAHour = 60 * 60 * 1000;

        let days = Math.floor(timeDifference / (secondsInADay) * 1);
        let hours = Math.floor((timeDifference % (secondsInADay)) / (secondsInAHour) * 1);
        let mins = Math.floor(((timeDifference % (secondsInADay)) % (secondsInAHour)) / (60 * 1000) * 1);
        let secs = Math.floor((((timeDifference % (secondsInADay)) % (secondsInAHour)) % (60 * 1000)) / 1000 * 1);

        return days + 'd,' + hours + 'h,' + mins + 'm'
        /*var idEl = document.getElementById(id);
        idEl.getElementsByClassName('days')[0].innerHTML = days;
        idEl.getElementsByClassName('hours')[0].innerHTML = hours;
        idEl.getElementsByClassName('minutes')[0].innerHTML = mins;
        idEl.getElementsByClassName('seconds')[0].innerHTML = secs;
*/
        //clearTimeout(countUpFromTime.interval);
        //countUpFromTime.interval = setTimeout(function(){ countUpFromTime(countFrom, id); }, 1000);
    };
    render() {
        let backgroundColor;
        let textColor;
        let billToParty;

        if (this.props.repair_order.bill_to_party.visual_indicator_background_color != null) {
            backgroundColor = this.props.repair_order.bill_to_party.visual_indicator_background_color.color.color;
            textColor = this.props.repair_order.bill_to_party.visual_indicator_background_color.color.text_color;
        } else {
            backgroundColor = 'white';
            textColor = 'black';
        }
        return (
            <Draggable draggableId={this.props.repair_order.id} index={this.props.index}>
                {(provided, snapshot) => (
                    <Container
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        isDragging={snapshot.isDragging}
                        order={this.props.repair_order}
                        className={'card border-secondary'}
                    >
                        <div className={'card-body mx-n2 my-n2'}>
                            <div className={'row mx-n2 my-n2'}>
                                <h6 className={'card-title'}>RO: {this.props.repair_order.order_number}</h6>
                            </div>
                            <hr/>
                            <Handle
                                className={'mx-n2 my-n2'}
                                {...provided.dragHandleProps}
                            >
                                <ul className="list-unstyled">
                                    <li><i className={'fas fa-address-book'} data-toggle={'tooltip'} title={'Customer'}/> {this.props.repair_order.customer.first_name} {this.props.repair_order.customer.last_name}</li>
                                    <li><i className={'fas fa-car'} data-toggle={'tooltip'} title={'Vehicle'}/> {this.props.repair_order.customer_vehicle.make} {this.props.repair_order.customer_vehicle.model}, {this.props.repair_order.customer_vehicle.color}</li>
                                    <li><i className={'fas fa-file-invoice-dollar'} data-toggle={'tooltip'} title={'Bill-to party'}/> {this.props.repair_order.bill_to_party.name}</li>
                                    <li><i className={'fas fa-clock'} data-toggle={'tooltip'} title={'Time in status'}/> {moment(this.props.repair_order.repair_order_status_type_changed_at).toNow(true)}</li>
                                </ul>
                            </Handle>
                            <hr/>
                            <CardFooter className={'mx-n2 my-n2'}>
                                <div className={'row'}>
                                    <div className={'col-sm-9'}>
                                        {this.waitingOnParts() }
                                        {this.renderSubletRequirementIcons() }
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <OrderLink className={'card-link'} order={this.props.repair_order} href={'/repair_orders/' + this.props.repair_order.id} target={'_blank'} data-toggle={'tooltip'} title={'Open repair order'}><i className={'fas fa-external-link-alt'}></i></OrderLink>
                                    </div>
                                </div>
                            </CardFooter>
                        </div>
                    </Container>
                )}
            </Draggable>
        )
    }
}
