import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 2px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 220px;
  
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;
const Title = styled.h6`
  padding: 4px;
  min-height: 45px;
`;

export default class BoardHeader extends React.Component {
    render() {
        console.log(this.props);
        return (
            <Container>
                <Title>{this.props.board.name}</Title>
            </Container>
        );
    }
}
