import { Controller } from "stimulus"
//import { DataTables } from "datatables.net-bs4"
//import $ from "jquery"

export default class extends Controller {
  static targets = [ "table" ]

    connect(){
      //this.indexTarget.DataTable()
      $('#example1').DataTable( {
        order: [[1,'asc'],[0,'asc']]
      })
    }
}
