// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require( 'jquery-ui' )
require('jquery-ui/ui/widget')
require('jquery-ui/ui/widgets/sortable')
require("trix")
require("@rails/actiontext")

import Rails from '@rails/ujs'
//import {} from 'jquery-ujs'
import 'bootstrap'
import flatpickr from 'flatpickr'
import 'controllers'
import 'admin-lte'
import 'selectize'
import { DragDropContext } from 'react-beautiful-dnd'
import 'bootstrap4-toggle'
import '../stylesheets/application'



// DataTables
require( 'datatables.net' )
require( 'datatables.net-bs4' )
require( 'datatables.net-buttons' )
require( 'datatables.net-buttons-bs4' )
//require( 'bootstrap-colorpicker')

// require( 'datatables.net-buttons/js/buttons.html5.js' )();
// require( 'datatables.net-buttons/js/buttons.print.js' )();
// require( 'datatables.net-colreorder-bs4' )();
// require( 'datatables.net-fixedcolumns-bs4' )();
// require( 'datatables.net-fixedheader-bs4' )();
// require( 'datatables.net-keytable-bs4' )();
// require( 'datatables.net-responsive-bs4' )();
// require( 'datatables.net-rowgroup-bs4' )();
// require( 'datatables.net-rowreorder-bs4' )();
// require( 'datatables.net-scroller-bs4' )();
// require( 'datatables.net-select-bs4' )();

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()

    flatpickr("[data-behavior='flatpickr']", {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d"
    })
    $("[data-behavior='sortable']").sortable({
        update: function(e, ui) {
            //console.log($(this).sortable('serialize',{ expression: '(.+)_(.+)' }))
            Rails.ajax({
                url: $(this).data("url"),
                type: "PATCH",
                data: $(this).sortable('serialize',{ expression: '(.+)_(.+)' })
            });
        }
    });
    $("[data-toggle='toggle']").bootstrapToggle();
    $("[data-behavior='selectize']").selectize();
    //Default to JSON responses for remote calls
    $.ajaxSetup({
        dataType: 'json'
    });

})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

//Custom JS
require( 'packs/repair_order' )

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
