import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/themes/dark.css";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller

export default class extends Flatpickr {
    //locales = {
    //  en: english
    //};

    connect() {
        //define locale and global flatpickr settings for all datepickers
        this.config = {
            altInput: true,
            altFormat: 'M j, Y',
            showMonths: 1,

            allowInput: false
        };

        super.connect();
    }
}
